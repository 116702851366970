import QuizCardComponent from "../../../components/ui/quiz-card/quiz-card";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import './view-quizzes.css'
import { NavLink } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AuthContext from '../../../context/auth.context'
import { showDToaster } from "../../../core/services/toaster-service/toaster.service";
import { getAllQuizzes } from "../../../core/services/quiz-service/quiz.service";
import { SpinnerComponent } from "../../../components/ui/spinner/spinner";
import { Button } from "@mui/material";
import { ReplayOutlined } from "@mui/icons-material";




export function ViewQuizPage() {
    const [quizzes,setQuizzes] = useState([]);
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
    getQuizzesFunc();
    console.log('running');
    localStorage.removeItem('currentQuesIndex');
    }, [])
    
    const getQuizzesFunc = () => {
        setShowLoader(true);
        getAllQuizzes().then((v)=>{
            let data = v?.data?.results;
            setQuizzes(data);
            setShowLoader(false);
            if(v){
                // showDToaster('Congo!!');
            }
        }).catch((err)=> {console.log(err); showDToaster(err?.response?.data?.message); setShowLoader(false);});
    }
    
    const user = useContext(AuthContext);

    return (<>
        {/* <h2>view All Quizzes</h2> */}
        {showLoader && <SpinnerComponent/>}
        {/* <p>{user?.name}</p> */}
        <Grid container spacing={2} className="grid-style">
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={6}>
            <button variant="contained" color='error' onClick={getQuizzesFunc} className="refresh-btn">
                  <ReplayOutlined/>
            </button>
            {/* <span>
                <NavLink to="/quiz">Quizzes</NavLink>
            </span>
            <span>
                <NavLink to="/quiz/all">Quizzes</NavLink>
            </span>
            <span>
                <NavLink to="/quiz/past">Quizzes</NavLink>
            </span> */}
            </Grid>
        </Grid>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} className="grid-style">
                {quizzes.map((quiz, index) => {
                    return (<>
                    <Grid item xs={6}>
                        <QuizCardComponent quiz={quiz} key={index} isQuiz={true} />
                    </Grid>
                    </>)
                })}
               
                {/* <Grid item xs={6}>
                    <QuizCardComponent />
                </Grid>
                <Grid item xs={6}>
                    <QuizCardComponent />
                </Grid>
                <Grid item xs={6}>
                    <QuizCardComponent />
                </Grid> */}
            </Grid>

        </Box>
    </>)
}