import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { NavLink, useNavigate } from 'react-router-dom';
import './forgot-password.css'
import { setStaticPassword } from '../../../core/services/login-service/login.service';
import { useState } from 'react';
import { showDToaster } from '../../../core/services/toaster-service/toaster.service';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <span color="inherit" href="#">
                knowalledge.com
            </span>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
export function ForgotPasswordPage() {
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        setShowLoader(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
        setStaticPassword({
            email: data.get('email'),
            // password: data.get('password'),
        }).then((v)=>{
            console.log(v);
            showDToaster('Email has been sent!');
            setTimeout(() => {
                setShowLoader(false);
                navigate('/login');
            }, 5000);
        });
    };
    return (<>
        <div className='login-form'>
        {/* <h2> Login here!</h2> */}
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <AppRegistrationIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Forgot Password
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <small> Random password will be sent to you via mail. </small>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={showLoader}
                        >
                            Send Mail
                        </Button>
                        {/* <small> Please try again after. </small> */}
                        <Grid container>
                            <Grid item xs>
                                {/* <Link href="#" variant="body2">
                                    Forgot password?
                                </Link> */}
                            </Grid>
                            <Grid item>
                                {/* <NavLink to='/register'>
                                    {"Don't have an account?"}
                                </NavLink> */}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        <div>
        </div>    
        </div>
    </>)
}

