import { getRequest, patchRequest, postRequest } from "../base/base-api.service";

function getFeed(pagination) {
    return new Promise((resolve, reject) => {
        getRequest(`posts/viewAll${pagination?.limit? '?limit='+pagination.limit:''}`)
            .then(response => {
                resolve(response); // Resolve with user data
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
    });
}

function getFeedByCategory(categoryId) {
    return new Promise((resolve, reject) => {
        getRequest(`posts/viewAllByCategory?category=${categoryId}`)
            .then(response => {
                resolve(response); // Resolve with user data
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
    });
}

function updateFeed(feedId, payload) {
    return new Promise((resolve, reject) => {
        patchRequest(`posts/update/${feedId}`,payload)
            .then(response => {
                resolve(response); // Resolve with user data
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
    });
}







export  {getFeed, getFeedByCategory, updateFeed};  