import { useEffect, useState } from "react";
import { getCategories } from "../../core/services/categories-service/categories.service";
import { showDToaster } from "../../core/services/toaster-service/toaster.service";
import Grid from '@mui/material/Grid';
import CategoriesCardComponent from "../../components/ui/categories-card/categories-card";
import { SpinnerComponent } from "../../components/ui/spinner/spinner";
import './post-by-categories.css'
import { NoDataFound } from "../../components/no-data-found/no-data-found";

export function PostByCategoryPage() {
    const [fetchedTopics, setFetchedTopics] = useState();
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        getCategoriesFunc();
    }, []);

    useEffect(() => {
    }, [fetchedTopics]);
    

    const getCategoriesFunc = () => {
        setShowLoader(true);
        getCategories().then((v)=>{
            console.log(v);
            let data = v?.data?.results;
            setFetchedTopics(data);
            setShowLoader(false);
            if(v){
                // showDToaster('Congo!!');
            }
        }).catch((err)=> {console.log(err); showDToaster(err?.response?.data?.message);setShowLoader(false);});
    }

    return (<>
    {showLoader && <SpinnerComponent/>}
    <div className="feed-container">
        {fetchedTopics?.length > 0 ? (
            <Grid container spacing={2}>
                {fetchedTopics?.map((category, index) => (
                    <Grid item xs={6} sm={4} md={6} key={index}>
                        <CategoriesCardComponent category={category} discover={true} />
                    </Grid>
                ))}
            </Grid>
        ) : (
           <NoDataFound/>
        )}
    </div>
    </>)
    
}