import { useEffect, useState } from "react";
import { getResults, getResultsByUserId } from "../../../core/services/result-service/result.service";
import { showDToaster } from "../../../core/services/toaster-service/toaster.service";
import QuizCardComponent from "../../../components/ui/quiz-card/quiz-card";
import { Box, Grid } from "@mui/material";
import { getUser } from "../../../core/services/login-service/login.service";
import { SpinnerComponent } from "../../../components/ui/spinner/spinner";
import { NoDataFound } from "../../../components/no-data-found/no-data-found";

export function MyQuizzesPage(){
    const [results,setResults] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    useEffect(() => {
    getResultsFunc();
    }, []);
    
    useEffect(() => {
        console.log(results);
    }, [results]);
    
    const getResultsFunc = () => {
        setShowLoader(true);
        let user = getUser(); 
        getResultsByUserId(user?.id).then((v)=>{
            let data = v?.data?.results;
            setResults(data);
            setShowLoader(false);
            if(v){
                // showDToaster('Congo!!');
            }
        }).catch((err)=> {console.log(err); showDToaster(err?.response?.data?.message); setShowLoader(false);});
    }

    return ( <>
    {showLoader && <SpinnerComponent/>}
        <h2> My Quizzes</h2>
        <Box sx={{ flexGrow: 1 }}>
            {results.length > 0 ? (
                <Grid container spacing={2} className="grid-style">
                    {results.map((result, i) => (
                        <Grid item xs={6} key={i}>
                            <QuizCardComponent quiz={result} isQuiz={false} />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <NoDataFound/>
            )}
        </Box>

        
    </>)
}