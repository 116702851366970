import { Bounce, toast } from 'react-toastify';

function showDToaster (TEXT) {
    return toast(TEXT,{theme: 'dark'})
}

function showWToaster (TEXT) {
    return toast(TEXT,{theme: 'dark'})
}

function showUnderDevToaster () {
    return toast.warn('Feature Under Development!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
}

export {showDToaster, showWToaster, showUnderDevToaster}