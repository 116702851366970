import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { NavLink, Navigate, useNavigate } from 'react-router-dom';
import { showDToaster } from '../../../core/services/toaster-service/toaster.service';
import { registerUser } from '../../../core/services/login-service/login.service';
import { FormControl, FormLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import { useState } from 'react';
import './register.css';
import { newSchoolArray, schoolsConstant } from '../../../core/util/constants/constant';

async function registerFunc(payload) {
    
}


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <span color="inherit" href="#">
                knowalledge.com
            </span>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export function RegisterPage() {
    const navigate = useNavigate();
    const [isStudent, setIsStudent] = useState(null);
    const [studentDetails, setStudentDetails] = useState('');

    const handleRadioChange = (event) => {
        setIsStudent(event.target.value === 'yes');
    };

    const handleDetailsChange = (event) => {
        setStudentDetails(event.target.value);
    };
   
    const handleRegister = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // registerFunc({
        //     name: data.get('name'),
        //     email: data.get('email'),
        //     password: data.get('password'),
        // });
        let fullName = data.get('firstName') +' ' +data.get('middleName')+' '+ data.get('lastName');
        let payload = {
                name: fullName,
                email: data.get('email'),
                password: data.get('password'),
                // isStudent: data.get('isStudent'), //todo upon backend
                // studentSchool: data.get('studentSchool'),
            };
            console.log(payload);
        registerUser(payload).then((v)=>{
            console.log(v);
            showDToaster("Congratulations! 🎉 You're officially in! Welcome aboard! 🚀");
            navigate('/');
        }).catch((err)=> {console.log(err); showDToaster(err?.response?.data?.message)});
    };
    return (<>
        <div className='login-form'>
        {/* <h2> Login here!</h2> */}
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                       Register
                    </Typography>
                    <Box component="form" onSubmit={handleRegister} noValidate sx={{ mt: 1 }}>
                        
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            name="firstName"
                            autoComplete="name"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="middleName"
                            label="Middle Name"
                            name="middleName"
                            autoComplete="middleName"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            autoComplete="lastName"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <div className='radiogrp'>
                            <FormControl component="fieldset">
                            <FormLabel component="legend">Are you a student?</FormLabel>
                            <Box display="flex" alignItems="center">
                            <RadioGroup
                                aria-label="student"
                                name="isStudent"
                                value={isStudent ? 'yes' : isStudent === false ? 'no' : ''}
                                onChange={handleRadioChange}
                                row
                            >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" /> 
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                            </Box>
                           
                            </FormControl>
                            {isStudent && (
                                // <TextField
                                //     margin="normal"
                                //     required
                                //     fullWidth
                                //     name="studentDetails"
                                //     label="Please provide your student details"
                                //     type="text"
                                //     id="studentDetails"
                                //     value={studentDetails}
                                //     onChange={handleDetailsChange}
                                // />

                                <FormControl fullWidth margin="normal" required>
                                    <Select
                                        name="studentSchool"
                                        label=""
                                        value={studentDetails}
                                        onChange={handleDetailsChange}
                                    >
                                         {newSchoolArray.map((school, index) => (
                                            <MenuItem value={school["School Name"]}>
                                                {school["School Name"]}
                                            </MenuItem>
                                        ))}
                                        <MenuItem value="Other">Other</MenuItem>
                                    </Select>
                                </FormControl>

                            )}
                        </div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Register
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <NavLink to={'/forgot-password'} variant="body2">
                                    Forgot password?
                                </NavLink>
                            </Grid>
                            <Grid item>
                                <NavLink to='/login'>
                                    {"Already have an account?"}
                                </NavLink>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </div>
        
    </>)
}