import { NavLink } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useState } from "react";
import './header.css';
import { logoutUserC, removeAll } from "../../../../core/services/login-service/login.service";
import { useNavigate} from "react-router-dom";

export function HeaderComponent() {
  const navigate = useNavigate();
  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu,setCurrentMenu] = useState(1) //? starting with feed menu

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const logoutUser = () => {
    removeAll();
    setTimeout(() => {
    navigate('/login');
    }, 200);
  }

  const selectedMenu = (selectedMenu)=>{
    setCurrentMenu(selectedMenu);
  }

    return (<>
        {/* <NavLink to="/login">Login</NavLink>
        <NavLink to="/register">Register</NavLink>
        <NavLink to="/quiz">Quizzes</NavLink>
        <NavLink to="/quiz/attend-quiz">Attend Quiz</NavLink>
        <NavLink to="/quiz/result">Quiz Result</NavLink>
        <NavLink to="/profile">Profile</NavLink>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={auth}
              onChange={handleChange}
              aria-label="login switch"
            />
          }
          label={auth ? 'Logout' : 'Login'}
        />
      </FormGroup> */}
      <AppBar position="static" className="header-bar">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" className={{ 'active-route': currentMenu === 0 }} component="div" sx={{ flexGrow: 2 }} >
            <NavLink to="/post-by-categories" onClick={() => { selectedMenu(0) }}>Discover</NavLink>
          </Typography>
          <Typography variant="h6" className={{ 'active-route': currentMenu === 1 }} component="div" sx={{ flexGrow: 3 }} >
            <NavLink to="/" onClick={() => { selectedMenu(1) }}>Feed</NavLink>
          </Typography>
          <Typography variant="h6" className={{ 'active-route': currentMenu === 2 }} component="div" sx={{ flexGrow: 1 }} >
            <NavLink to="/select-quiz" onClick={() => { selectedMenu(2) }}>Quizzes</NavLink>
          </Typography>

          {auth && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                className="header-avatar"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <NavLink to="/profile">
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                </NavLink>
                <NavLink to="/quiz/my-quizzes">
                  <MenuItem onClick={handleClose}>My Quizzes</MenuItem>
                </NavLink>
                <NavLink to="/categories">
                  <MenuItem onClick={handleClose}>My Topics</MenuItem>
                </NavLink>
                  <MenuItem onClick={logoutUser}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {/* <NavLink to="/login">Login</NavLink>
      <NavLink to="/register">Register</NavLink>
      <NavLink to="/quiz">Quizzes</NavLink>
      <NavLink to="/quiz/attend-quiz">Attend Quiz</NavLink>
      <NavLink to="/quiz/result">Quiz Result</NavLink>
      <NavLink to="/profile">Profile</NavLink> */}

    </>)
}