import { MagnifyingGlass } from 'react-loader-spinner'
import './spinner.css'

export function SpinnerComponent(params) {
    return (<>
        <div className='loader'>
            <MagnifyingGlass
                visible={true}
                height="180"
                width="120"
                ariaLabel="magnifying-glass-loading"
                wrapperStyle={{}}
                wrapperClass="magnifying-glass-wrapper"
                glassColor="#c0efff"
                color="#e15b64"
            />
        </div>
    </>)
}