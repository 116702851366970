import { getRequest, postRequest } from "../base/base-api.service";



function saveResult(payload) {
    return new Promise((resolve, reject) => {
        postRequest(`results/create`,payload)
            .then(response => {
                resolve(response); // Resolve with user data
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
    });
}

function getResult(resultID) {
    return new Promise((resolve, reject) => {
        getRequest(`results/view/${resultID}`)
            .then(response => {
                resolve(response); // Resolve with user data
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
    });
}

function getResults() {
    return new Promise((resolve, reject) => {
        getRequest(`results/viewAll`)
            .then(response => {
                resolve(response); // Resolve with user data
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
    });
}

function getResultsByUserId(userId) {
    return new Promise((resolve, reject) => {
        getRequest(`results/viewAll${userId? '?attemptedUserId='+ userId:''}`)
            .then(response => {
                resolve(response); // Resolve with user data
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
    });
}
export  {saveResult, getResult, getResults, getResultsByUserId};  