import './login-prompt.css'
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { NavLink, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';
import logo from '../../../assets/kn-logo.jpg'

function getDetails() {
    console.log('called');
    // axios.get('https://knowalledgebe.cloudjiffy.net/v1/posts/viewAll', {
    //     params: {
    //       ID: 12345
    //     }
    //   })
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   })
    //   .finally(function () {
    //     // always executed
    //   });
}





export function LoginPromptComponent() {

    useEffect(() => {

        return () => {
            getDetails();
        }
      },[])




    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));
      
    return (<>
    <div className="login-prompt">
        <div className="container">
                <div className="website-logo"><img src={logo} alt=""/></div>
                <div className="bottom-fixed-link">
                <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Item> 
                    <span className='login'>
                    <NavLink to='/register'>Register</NavLink>
                    </span> 
                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item >
                    <span className='login' >
                    <NavLink to='/login'>Login</NavLink>
                    </span>
                    </Item>
                </Grid>
                </Grid>
            </div>
        </div>
        </div>
    </>)
}