import Button from '@mui/material/Button';
import ImgCardComponent from '../../components/ui/img-card/img-card';
import { getFeed, updateFeed } from '../../core/services/feed-service/feed.service';
import { showDToaster } from '../../core/services/toaster-service/toaster.service';
import { useCallback, useEffect, useState } from 'react';
import './feed.css'
import { SpinnerComponent } from '../../components/ui/spinner/spinner';
import { changeRefreshToken, getUser, removeUser } from '../../core/services/login-service/login.service';
import { NoDataFound } from '../../components/no-data-found/no-data-found';
import IconButton from '@mui/material/IconButton';
import ReactConfetti from 'react-confetti';
import { RestartAlt } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export function FeedPage() {
    const [fetchedFeed, setFetchedFeed] = useState();
    const [showLoader, setShowLoader] = useState(true);
    const [feedPagination, setFeedPagination] = useState({limit:20});
    const [totalFeeds, setTotalFeeds] = useState(0);
    const navigate = useNavigate();
    
    useEffect(() => {
        getFeedFunc();
        changeRefreshTokenFunc();
    },[])

    useEffect(() => {
        console.log(fetchedFeed);
    },[fetchedFeed]);

    useEffect(() => {
        getFeedFunc();
    },[feedPagination]);

    const getFeedFunc = () => {
        setShowLoader(true);
        getFeed(feedPagination).then((v)=>{
            let data = v?.data?.results;
            setFetchedFeed(data);
            setTotalFeeds(v?.data?.totalResults)
            //setFetchedFeed((prevFeed) => [...prevFeed, ...data]);
            setShowLoader(false);
            if(v){
                // showDToaster('Congo!!');
            }
        }).catch((err)=> {console.log(err); showDToaster(err?.response?.data?.message); setShowLoader(false);});
    }

    const updateSingleFeedFunc = (postId, payload) => {
        // setShowLoader(true);
        updateFeed(postId, payload).then((v)=>{
            // setShowLoader(false);
        }).catch((err)=> {console.log(err); showDToaster(err?.response?.data?.message); setShowLoader(false);});
    }

    const currentUserLikedOrNot = (post) => {
        const user = getUser();
        return post?.likedByUsers.includes(user?.id);
    }

    const likedFromChild = (post,likeStatus)=> {
        const user = getUser();
        console.log(post);
        console.log(likeStatus);
        if(likeStatus){
            post.likedByUsers.push(user?.id)
        }else{
            post.likedByUsers = post.likedByUsers.filter((likers)=> likers !== user?.id);
        }
        let paylod = {
            likedByUsers: post.likedByUsers,
        }
        console.log(paylod);
        updateSingleFeedFunc(post?.id,paylod)
    }

    const changeRefreshTokenFunc = ()=>{
        changeRefreshToken().then((v)=>{
          setShowLoader(false);
        }).catch((err)=> {console.log(err); showDToaster(err?.response?.data?.message); setShowLoader(false); removeUser();
            navigate('/');
        });
    };
    
    return (<>
    {/* <ReactConfetti
      width={'100vw'}
      height={'100vh'}
    /> */}
    {/* <h2>Feed is here!</h2> */}
    {showLoader && <SpinnerComponent/>}
    {/* <Button variant="contained">Hello world</Button> */}
    <div className='feed-container' key={1}>
    {fetchedFeed?.length > 0 ? 
        (
        fetchedFeed?.map((post, index) => {
            const liked =  currentUserLikedOrNot(post);
            return (<>
                <ImgCardComponent key={index} post={post} onLike={likedFromChild} prevLiked={liked}/>
                {showLoader && <SpinnerComponent/>}
            </>)
        })
        ):
        (
            <NoDataFound/>
         )
       } 
    {/* <ImgCardComponent props={{postName:"m Fost",liked:true}} onLike={likedFromChild}/>
    <ImgCardComponent props={{postName:"2m Fost",liked:false}} onLike={likedFromChild}/> */}
    </div>
    {totalFeeds !== fetchedFeed?.length?<IconButton onClick={()=>{setFeedPagination({limit:feedPagination?.limit+20})}}>
        <RestartAlt />
        Load more....
    </IconButton>:''}
    </>)
}